<template>
  <v-dialog v-model="value" persistent max-width="800px">
    <v-card color="grey lighten-3">
      <v-card-title class="bg-gradient-primary-2">
        <span class="white--text">Add New Item</span>
      </v-card-title>
      <v-card-text class="pt-4">
        <v-form ref="form" v-model="valid">
          <div v-for="index in formCount" :key="index" :class="{ 'new-field': index >= 1 }">
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-model="security"
                  label="Security"
                  :search-input.sync="search"
                  :loading="loading.security"
                  :items="securityOptions"
                  item-text="name"
                  hide-details="auto"
                  outlined
                  dense
                  no-filter
                  :rules="[v => !!v || 'Please Select Security']"
                  @input="selectSecurity"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="6">
                <ac-input v-model="exchange" label="Exchange" outlined></ac-input>
              </v-col>
              <v-col cols="6">
                <ac-input v-model="stokeCode" label="Name" outlined></ac-input>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <ac-date-picker v-model="joinDate" label="Execution Date" outlined />
              </v-col>
              <v-col>
                <ac-select
                  label="Buy/Sell"
                  v-model="details"
                  :items="[
                    { id: 'Buy', name: 'Buy' },
                    { id: 'Sell', name: 'Sell' },
                  ]"
                  class="company-select"
                />
              </v-col>
              <v-col>
                <ac-select
                  label="Investment Class"
                  v-model="investmentClass"
                  :items="investments"
                  class="company-select"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <ac-input v-if="followUp" v-model="strike" label="Strike Price" outlined></ac-input>
              </v-col>
            </v-row>
          </div>
        </v-form>
        <v-row class="mt-4">
          <v-col cols="auto">
            <ac-button title="Add" color="primary" @click="addForm" />
          </v-col>
          <v-col cols="auto">
            <ac-button title="Remove" color="error" @click="removeForm" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <ac-button title="Cancel" color="error" outlined @click="onClose" />
        <ac-button title="Submit" color="success" :loading="loading" @click="onSubmit" />
        <upload-modal v-model="showUpload" title="approval" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment'
import { mdiUpload } from '@mdi/js'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { emailValidator, required } from '@core/utils/validation'

export default {
  name: 'upsertPortfolioModal',
  components: {
    AcInput: () => import('@/components/AcInput'),
    AcButton: () => import('@/components/AcButton'),
    AcSelect: () => import('@/components/AcSelect'),
    AcComboBox: () => import('@/components/AcComboBox.vue'),
    AcDatePicker: () => import('@/components/AcDatePicker'),
    AcIconBtn: () => import('@/components/AcIconBtn'),
    UploadModal: () => import('@/components/modals/UploadModal'),
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editItem: {
      type: Object,
    },
    companyUser: {
      type: Array,
    },
    companyApprover: {
      type: Array,
    },
    transactionStatement: {
      type: Array,
    },
  },
  data() {
    return {
      icons: {
        mdiUpload,
      },
      investments: [
        { id: 'Exchange Traded Derivatives', name: 'Exchange Traded Derivatives' },
        { id: 'IPO', name: 'IPO' },
        { id: 'Listed Securities', name: 'Listed Securities' },
        { id: 'Mutual or Authorised Funds', name: 'Mutual or Authorised Funds' },
        { id: 'OTC-Derivatives', name: 'OTC-Derivatives' },
        { id: 'Private Placement', name: 'Private Placement' },
        { id: 'Unauthorised Funds', name: 'Unauthorised Funds' },
        { id: 'Discretionary Account', name: 'Discretionary Account' },
      ],
      details: '',
      investmentClass: '',
      userData: null,
      formCount: 1,
      brokersNumber: '',
      gtcOrder: '',
      showUpload: false,
      search: null,
      valid: false,
      loading: false,
      validators: {
        required,
        emailValidator,
      },
      // * form
      security: null,
      surName: null,
      exchange: null,
      stokeCode: null,
      // selectedCompany: null,
      approver: null,
      joinDate: null,
      brokers: [],
      strike: null,
      selectedStatement: null,

      followUp: false,
      brokerList: [],
      selectedCondition: null,
      statement: [{ id: 'NA', name: 'NA' }],
      conditionOptions: [
        { id: 'NA', name: 'NA' },
        { id: 'Exempt product', name: 'Exempt product' },
        { id: 'Involuntary transaction', name: 'Involuntary transaction' },
      ],
    }
  },

  computed: {
    ...mapGetters('app', ['companies', 'selectedCompany']),
    ...mapGetters('approval', ['accountNumbers', 'getApprovals', 'securityList']),
    ...mapGetters('transactionStaff', ['companyStaffAccounts', 'getTransactionStaff']),

    securityOptions() {
      if (this.checkObject(this.editItem)) {
        const { exchange, security, ticker } = this.editItem
        const options = [
          {
            name: security,
            ticker,
            exchange: exchange,
          },
        ]
        return options.concat(this.securityList)
      } else {
        return this.securityList
      }
    },
  },
  methods: {
    ...mapActions('approval', ['createApproval', 'updateApproval', 'fetchSecurityList']),
    ...mapActions('transactionStaff', ['addTransactionStaff']),

    ...mapMutations('approval', ['SET_APPROVAL', 'GET_ACCOUNT', 'SET_SECURITY']),
    addForm() {
      this.formCount++
    },
    removeForm() {
      if (this.formCount > 1) {
        this.formCount--
      }
    },
    filterBroker(id) {
      this.brokerList = []
      this.brokers = []
      this.statement = []
      this.brokerList = this.companyStaffAccounts.filter(account => account.userID == id)

      this.brokerList.forEach(element => {
        this.brokers.push({ id: element.id, name: element.accountNumber })
      })

      this.statement = this.transactionStatement.filter(
        statement => statement.author == this.userData.id,
      )

      this.statement.unshift({ id: 'NA', name: 'NA' }) // add the object at the beginning of the array
    },

    async onSubmit() {
      this.$refs.form.validate()
      if (this.valid) {
        this.loading = true

        const brokerData = this.brokerList.filter(broker => broker.id == this.brokersNumber)
        const payload = {
          broker: brokerData,
          security: this.security,
          details: this.details,
          exchange: this.exchange,
          user: this.userData.id,
          statement: this.selectedStatement,
          date: moment(this.joinDate).format('DD MMM YYYY '),
          file: '',
          investmentClass: this.investmentClass,
          strikePrice: this.strike,
          condition: this.selectedCondition,
          symbol: this.stokeCode,
          followUp: this.followUp,
        }

        const { success, message } = await this.addTransactionStaff(payload)
        if (success) {
          this.AcNotify({
            type: 'success',
            message,
            position: 'bottom',
          })
        } else {
          this.AcNotify({
            type: 'error',
            message,
            position: 'bottom',
          })
        }
        this.onClose(true)
        this.loading = false
      }
    },
    onClose(refetch) {
      this.security = null
      this.exchange = null
      this.stokeCode = null
      this.userData = null
      this.brokersNumber = ''
      this.details = ''
      this.investmentClass = ''
      this.gtcOrder = ''
      this.approver = null
      this.joinDate = null
      if (refetch) {
        const { selectedCompany } = this
        this.$emit('onClose', { selectedCompany })
      }

      this.reset()
      this.$emit('input', false)
    },
    async getSecurityList(val) {
      await this.fetchSecurityList(val)
    },
    selectSecurity(val) {
      const { securityList } = this
      const securityObj = securityList.find(obj => {
        return obj.name === val
      })
      if (securityObj != undefined) {
        this.stokeCode = securityObj.symbol
        this.exchange = securityObj.exch
      }
    },
    reset() {
      this.licenses = []
      this.$refs.form.reset()
    },
    getCompanyName(id) {
      const selectedCompany = this.companies.find(o => o.id === id)
      return selectedCompany?.name
    },
  },

  watch: {
    search(val) {
      val && val !== this.secasdasdurity && this.getSecurityList(val)
    },
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          if (this.checkObject(this.editItem) && this.editItem != undefined) {
            const {
              accountNumber,
              approvalDate,
              approver,
              buyOrSell,
              exchange,
              gtcOrder,
              investmentClass,
              security,
              ticker,
              userName,
            } = this.editItem
            this.brokers = this.accountNumbers.filter(account => account.name == accountNumber)
            this.security = security
            this.exchange = exchange
            this.stokeCode = ticker
            this.userData = userName
            this.brokersNumber = this.brokers[0].id
            this.details = buyOrSell
            this.investmentClass = investmentClass
            this.gtcOrder = gtcOrder
            this.approver = approver
            this.joinDate = approvalDate
          }
        }
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.new-field {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
}
</style>
